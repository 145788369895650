import * as React from "react";
import { Box, Typography, Button } from "@mui/material";
import "./UserReport.scss";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { getUserData } from "../../features/auth/authSlice";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import {
  checkSiteUniqueCode,
  fetchSiteById,
} from "../../features/manager/asyncActions";
import { CSVLink } from "react-csv";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import {
  getPcReportData,
  getSiteReportData,
} from "../../features/PC/asyncActions";
import moment from "moment";

interface Data {
  user_id: number;
  child_name: string;
  family_name: string;
  email: string;
  tracking_status: string;
  end_date: string;
  last_access: string;
  action_plan: string;
  pcs?: any[];
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

const progressStatus = (status: any, actionPlan: any) => {
  switch (status) {
    case 0:
      return "Account created";
    case 1:
      return "Risk Assessment";
    case 2:
      return `Action Plan - ${actionPlan}`;
    case 3:
      return "Completed";
  }
};

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const renderHeadCells = (type: string): HeadCell[] => {
  const headCells: HeadCell[] = [
    {
      id: "child_name",
      numeric: false,
      disablePadding: true,
      label: "Child's Name",
    },
    {
      id: "family_name",
      numeric: false,
      disablePadding: true,
      label: "Family Name",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Account",
    },
    {
      id: "tracking_status",
      numeric: false,
      disablePadding: false,
      label: "Progress",
    },
    {
      id: "end_date",
      numeric: false,
      disablePadding: false,
      label: "Action Plan End Date",
    },
    {
      id: "last_access",
      numeric: false,
      disablePadding: false,
      label: "Last Access",
    },
  ];

  if (type === "site") {
    headCells.push({
      id: "pcs",
      numeric: false,
      disablePadding: false,
      label: "Providers/Counselors",
    });
  }

  return headCells;
};

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  type: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, type } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead className="table-header">
      <TableRow className="table-row">
        {renderHeadCells(type).map((headCell) => (
          <TableCell
            className="table-cell"
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const UserReport = () => {
  const { t } = useTranslation();
  const { type, id } = useParams();
  const userData = useAppSelector(getUserData);
  const [siteName, setSiteName] = React.useState("");
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("child_name");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState<Data | any>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const renderCsvData = () => {
    let csvData = [
      [
        "Child's Name",
        "Family Name",
        "Account",
        "Progress",
        "Action Plan End Date",
        "Last Access",
        "Provider/Counselor",
      ],
      ...rows.map(
        ({
          child_name,
          family_name,
          email,
          tracking_status,
          end_date,
          action_plan,
          last_access,
          pcs = [],
        }: any) => [
          child_name,
          family_name,
          email,
          progressStatus(tracking_status, action_plan),
          end_date && tracking_status === 2
            ? moment(end_date).format("MM/DD/YYYY")
            : "",
          last_access ? moment(last_access).format("MM/DD/YYYY") : "",
          pcs
            ?.map((pc: any) => `${pc.pc_type === 2 ? "P" : "C"}: ${pc.pc_name}`)
            .join("\n"),
        ]
      ),
    ];

    if (type !== "site") {
      csvData = csvData.map((row) => row.slice(0, -1));
    }

    return csvData;
  };

  const getSiteName = async () => {
    const { data } = await (type === "site"
      ? fetchSiteById(id)
      : checkSiteUniqueCode({
          siteCode: userData.authorized_code,
        }));
    setSiteName(data?.description ? data.description : null);
  };

  const fetchPcReportData = async () => {
    const { data } = await (type === "pc"
      ? getPcReportData(id)
      : getSiteReportData(id));
    if (data.length > 0) setRows(data);
    else setRows([]);
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    getSiteName();
    fetchPcReportData();
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n: any) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <>
      <LanguageSupport showTranslateComponent showBackArrowComponent />
      <Box className="userReportPage">
        <Box className="userReportPage__header">
          <Box display="flex" flexDirection="column" width="100%">
            <Typography fontSize="2rem" color="#005994" fontWeight="700">
              {siteName}
            </Typography>
          </Box>

          <CSVLink
            filename={`${siteName}-${
              type === "pc" ? "pcId" : "siteId"
            }-${id}.csv`}
            data={renderCsvData()}
            style={{ marginLeft: "auto" }}
          >
            <Button
              variant="outlined"
              className="btn"
              style={{ width: "270px" }}
            >
              {t("pc-dashboard.2")}
            </Button>
          </CSVLink>
        </Box>
        <Box className="userReportPage__content">
          <TableContainer className="table-container">
            <Table
              sx={{ minWidth: "100%" }}
              aria-labelledby="tableTitle"
              size="medium"
              className="table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                type={type ? type : "pc"}
              />
              <TableBody>
                {visibleRows.map((row: any, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.user_id}
                      sx={{ cursor: "pointer" }}
                      className="table-row"
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className="table-cell"
                      >
                        {row.child_name}
                      </TableCell>
                      <TableCell align="left" className="table-cell">
                        {row.family_name}
                      </TableCell>
                      <TableCell align="left" className="table-cell">
                        {row.email}
                      </TableCell>
                      <TableCell
                        align="left"
                        className="table-cell"
                        style={{ color: "#005994" }}
                      >
                        {progressStatus(row.tracking_status, row?.action_plan)}
                      </TableCell>
                      <TableCell align="left" className="table-cell">
                        {row.end_date && row.tracking_status === 2
                          ? moment(row.end_date).format("MM/DD/YYYY")
                          : ""}
                      </TableCell>
                      <TableCell align="left" className="table-cell">
                        {row.last_access
                          ? moment(row.last_access).format("MM/DD/YYYY")
                          : ""}
                      </TableCell>
                      {type === "site" && (
                        <TableCell align="left" className="table-cell">
                          {
                            <div>
                              {row?.pcs?.map((pc: any) => (
                                <Typography>
                                  <strong>
                                    {`${pc.pc_type === 2 ? "P" : "C"}`}:
                                  </strong>
                                  {`${pc.pc_name}`}
                                </Typography>
                              ))}
                            </div>
                          }
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </>
  );
};

export default UserReport;
