import * as React from "react";
import {
  Box,
  FormControl,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  IconButton,
  Button,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  AlertColor,
} from "@mui/material";
import VideoEmbed from "../../components/video-embed/VideoEmbed";
import "./SignUp.scss";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";
import { checkEmpty } from "../../utils/common/checkEmpty";
import CustomAlert from "../../components/custom-alert/CustomAlert";
import { authSignUp } from "../../features/auth/asyncAction";
import { getUserData } from "../../features/auth/authSlice";
import { useAppSelector } from "../../app/hooks";
import { redirectFromSignIn } from "../../utils/common/redirect";
import { useTranslation } from "react-i18next";
import {
  getGlobalLanguage,
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import {
  signUpEnIcon,
  signUpEsIcon,
  signUpPbIcon,
  signUpSwIcon,
  sign_intro_videos,
} from "../../utils/common/global";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import { store } from "../../app/store";
import { validPassword } from "../../utils/common/checkValidator";
import { checkSiteUniqueCode } from "../../features/manager/asyncActions";
import PasswordValidation from "../../components/password-validation/PasswordValidation";

const SignUp = () => {
  const { t } = useTranslation();
  const language = useAppSelector(getGlobalLanguage);
  const navigate = useNavigate();
  const authToken = localStorage.getItem("access-token");
  const userData = useAppSelector(getUserData);
  const [email, setEmail] = React.useState<string>("");
  const [siteCode, setSiteCode] = React.useState<string>("");
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");
  const [userType, setUserType] = React.useState<number | null>(null);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    React.useState<boolean>(false);
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<any>("");
  const [messageType, setMessageType] = React.useState<AlertColor>("info");
  const [hideDuration, setHideDuration] = React.useState<number>(3000);
  const showMemberStatus = useAppSelector(showMember);

  const isCorrectSiteCode = async (siteCode: string): Promise<any> => {
    const data = await checkSiteUniqueCode({ siteCode });
    return data;
  };

  const signupHandler = async (ev: any) => {
    ev.preventDefault();
    const data = {
      email,
      password,
      language: "English",
      userType,
      ...{ ...(userType !== 1 && userType !== 5 ? { siteCode } : {}) },
      firstName,
      lastName,
    };
    const validPasswordStatus = validPassword(password);
    if (validPasswordStatus) {
      if (password === confirmPassword) {
        const state = checkEmpty(data);
        if (state) {
          const existData =
            userType !== 1 && userType !== 5
              ? await isCorrectSiteCode(siteCode)
              : { isAllowed: true };
          if (existData?.isAllowed) {
            const resp = await authSignUp({
              ...data,
              siteCode: siteCode ? siteCode : null,
            });
            setShowMessage(true);
            setMessageType(resp.status);
            setMessage(resp.message);
            setHideDuration(3000);
          } else {
            setShowMessage(true);
            setMessage(existData?.message);
            setMessageType(existData?.status);
            setHideDuration(3000);
          }
        } else {
          setShowMessage(true);
          setMessage(t("common.16"));
          setMessageType("error");
          setHideDuration(3000);
        }
      } else {
        setShowMessage(true);
        setMessage("Password didn't match");
        setMessageType("error");
        setHideDuration(3000);
      }
    }
  };

  React.useEffect(() => {
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
    if (authToken && userData?.role_id && userData?.isMove)
      redirectFromSignIn(navigate, userData?.role_id, userData?.isMove);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (
      messageType === "success" &&
      message === "Email verification send successfully"
    ) {
      setTimeout(() => {
        navigate("/sign-in");
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const renderSignUpIcon = () => {
    switch (language) {
      case "en":
        return signUpEnIcon;
      case "es":
        return signUpEsIcon;
      case "pb":
        return signUpPbIcon;
      case "sw":
        return signUpSwIcon;
      default:
        return signUpEnIcon;
    }
  };

  return (
    <>
      <CustomAlert
        type={messageType}
        open={showMessage}
        closeHandler={() => setShowMessage(false)}
        message={message}
        hideDuration={hideDuration}
      />
      <LanguageSupport showTranslateComponent />
      <Box className="signUpPage">
        <Box className="signUpPage__intro">
          <Typography
            textAlign="center"
            fontSize="1.1rem"
            fontWeight="500"
            margin="0.2rem 0.5rem"
          >
            {t("sign-up.1")}
          </Typography>
          <Typography
            textAlign="center"
            fontSize="1.1rem"
            fontWeight="500"
            margin="0.2rem 0.5rem"
          >
            {t("sign-up.2")}
          </Typography>
          <Typography
            textAlign="center"
            fontSize="1.1rem"
            fontWeight="500"
            margin="0.2rem 0.5rem"
          >
            {t("sign-up.13")}
          </Typography>
          <VideoEmbed embedId={sign_intro_videos[language]} />
        </Box>
        <Paper className="signUpPage__signUp">
          <Box className="imgHintContainer">
            <img
              src={renderSignUpIcon()}
              alt=""
              width="100%"
              height="auto"
              className="imgHint"
            />
          </Box>
          <Typography variant="h4" style={{ marginBottom: "1rem" }}>
            {t("sign-up.3")}
          </Typography>
          <Typography style={{ color: "#00000080" }}>
            {t("sign-up.4")}
            <span className="text-danger"> *</span>
          </Typography>
          <Box sx={{ marginTop: "1rem" }}>
            <RadioGroup
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="userType"
              value={userType}
              onChange={(ev: any) => setUserType(Number(ev.target.value))}
            >
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ textAlign: "start" }}>
                  <FormControlLabel
                    value={4}
                    control={<Radio />}
                    label={t("sign-up.5")}
                  />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "start" }}>
                  <FormControlLabel
                    value={5}
                    control={<Radio />}
                    label={t("sign-up.20")}
                  />
                </Grid>
                {/* <Grid item xs={6} sx={{ textAlign: "start" }}>
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label={t("sign-up.6")}
                  />
                </Grid> */}
                <Grid item xs={6} sx={{ textAlign: "start" }}>
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label={t("sign-up.7")}
                  />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "start" }}>
                  <FormControlLabel
                    value={3}
                    control={<Radio />}
                    label={t("sign-up.8")}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </Box>
          <Box
            component="form"
            className="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            {userType !== 1 && userType !== 5 && (
              <FormControl fullWidth>
                <TextField
                  // label={t("sign-up.9")}
                  label={"Site code"}
                  type="text"
                  variant="standard"
                  placeholder="Site code"
                  style={{ width: "100%" }}
                  value={siteCode}
                  onChange={(ev: any) => setSiteCode(ev.target.value)}
                  error={messageType === "error" && !siteCode ? true : false}
                  required
                />
              </FormControl>
            )}

            <Box width="100%" display="flex" gap={4}>
              <FormControl fullWidth>
                <TextField
                  label={t("custom-dialog.1")}
                  type="text"
                  variant="standard"
                  placeholder={t("custom-dialog.1")}
                  style={{ width: "100%" }}
                  value={firstName}
                  onChange={(ev: any) => setFirstName(ev.target.value)}
                  error={messageType === "error" && !firstName ? true : false}
                  required
                />
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  label={t("custom-dialog.2")}
                  type="text"
                  variant="standard"
                  placeholder={t("custom-dialog.2")}
                  style={{ width: "100%" }}
                  value={lastName}
                  onChange={(ev: any) => setLastName(ev.target.value)}
                  error={messageType === "error" && !lastName ? true : false}
                  required
                />
              </FormControl>
            </Box>

            <FormControl fullWidth>
              <TextField
                label={t("sign-up.9")}
                type="email"
                variant="standard"
                placeholder="example@email.com"
                style={{ width: "100%" }}
                value={email}
                onChange={(ev: any) => setEmail(ev.target.value)}
                error={messageType === "error" && !email ? true : false}
                required
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label={t("sign-up.10")}
                style={{ width: "100%" }}
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(ev: any) => setPassword(ev.target.value)}
                variant="standard"
                placeholder="Enter your password"
                error={messageType === "error" && !password ? true : false}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {password && !validPassword(password) && (
                <PasswordValidation password={password} />
              )}
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label={t("sign-up.11")}
                style={{ width: "100%" }}
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(event: any) =>
                  setConfirmPassword(event.target.value)
                }
                variant="standard"
                placeholder="Enter your password"
                error={
                  messageType === "error" && !confirmPassword ? true : false
                }
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              className="login-btn"
              onClick={signupHandler}
              disabled={!validPassword(password)}
            >
              {t("common.18")}
            </Button>
            <Typography className="sign-up">
              {t("sign-up.14")} ? <Link to="/sign-in">{t("sign-up.15")}</Link>
            </Typography>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default SignUp;
