import React from "react";
import { AlertColor, Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { getCurrentMember } from "../../../features/user/userSlice";
import { useNavigate } from "react-router-dom";
import { isEmpty, capitalize } from "lodash";
import {
  ImagesArrayFood,
  ImagesArrayDrink,
} from "../../../utils/common/global";
import {
  getSelectedAnswers,
  updateSelectedAnswers,
} from "../../../features/global/globalSlice";
import MealCard from "./components/meal-card/MealCard";
import { store } from "../../../app/store";
import { checkListEmpty } from "../../../utils/common/checkEmpty";
import { getFoodOrDrinksId } from "../../../features/user/asyncActions";
import CustomAlert from "../../../components/custom-alert/CustomAlert";

interface DietSummaryProps {
  setDietList?: (val: any) => void;
}

const DietSummary = ({ setDietList }: DietSummaryProps) => {
  const { t } = useTranslation();
  const memberData = useAppSelector(getCurrentMember);
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [messageType, setMessageType] = React.useState<AlertColor>("error");
  const selectedAnswers = useAppSelector(getSelectedAnswers);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);

  const [timeArray, setTimeArray] = React.useState([
    { name: "6:00 am", label: "6 AM", isSelect: false, values: [] },
    { name: "7:00 am", label: "7 AM", isSelect: false, values: [] },
    { name: "8:00 am", label: "8 AM", isSelect: false, values: [] },
    { name: "9:00 am", label: "9 AM", isSelect: false, values: [] },
    { name: "10:00 am", label: "10 AM", isSelect: false, values: [] },
    { name: "11:00 am", label: "11 AM", isSelect: false, values: [] },
    { name: "12:00 pm", label: "12 PM", isSelect: false, values: [] },
    { name: "1:00 pm", label: "1 PM", isSelect: false, values: [] },
    { name: "2:00 pm", label: "2 PM", isSelect: false, values: [] },
    { name: "3:00 pm", label: "3 PM", isSelect: false, values: [] },
    { name: "4:00 pm", label: "4 PM", isSelect: false, values: [] },
    { name: "5:00 pm", label: "5 PM", isSelect: false, values: [] },
    { name: "6:00 pm", label: "6 PM", isSelect: false, values: [] },
    { name: "7:00 pm", label: "7 PM", isSelect: false, values: [] },
    { name: "8:00 pm", label: "8 PM", isSelect: false, values: [] },
    { name: "9:00 pm", label: "9 PM", isSelect: false, values: [] },
    { name: "10:00 pm", label: "10 PM", isSelect: false, values: [] },
    { name: "11:00 pm", label: "11 PM", isSelect: false, values: [] },
    { name: "12:00 am", label: "12 AM", isSelect: false, values: [] },
    { name: "1:00 am", label: "1 AM", isSelect: false, values: [] },
    { name: "2:00 am", label: "2 AM", isSelect: false, values: [] },
    { name: "3:00 am", label: "3 AM", isSelect: false, values: [] },
    { name: "4:00 am", label: "4 AM", isSelect: false, values: [] },
    { name: "5:00 am", label: "5 AM", isSelect: false, values: [] },
  ]);

  const getSelectedFoodDrinks = () => {
    let selectedTimes: any = {};
    selectedTimes = timeArray.filter((val) => !isEmpty(val.values));
    return selectedTimes;
  };

  const toggleImagesInTimeArray = (key: string, imgId: any, type?: string) => {
    let activeTime: any = {};
    let newValues: any = [];
    activeTime = timeArray.find((val) => val.name === key);
    const idx = activeTime.values.indexOf(imgId);
    if (idx > -1) {
      newValues = [...activeTime.values.filter((val: any) => val !== imgId)];
    } else {
      newValues = [...activeTime.values, imgId];
    }

    let newArray: any = [];
    timeArray.forEach((val, i) => {
      if (val.name === activeTime.name) {
        newArray = [
          ...newArray,
          { name: val.name, label: val.label, values: newValues },
        ];
      } else {
        newArray = [
          ...newArray,
          { name: val.name, label: val.label, values: val.values },
        ];
      }
    });
    setTimeArray(newArray);
    store.dispatch(
      updateSelectedAnswers({
        ...selectedAnswers,
        timeArray: [...newArray],
      })
    );
  };

  const getImageURL = (imageId: string) => {
    const selectImage = [...ImagesArrayFood, ...ImagesArrayDrink].find(
      (image: any) => image.imageId === imageId
    );
    return selectImage?.image ? selectImage.image : "";
  };

  React.useEffect(() => {
    selectedAnswers?.timeArray?.length > 0 &&
      setTimeArray(selectedAnswers.timeArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeArray]);

  const continueHandler = async (ev: any) => {
    ev.preventDefault();
    const newTimeArray = [...timeArray.map((option) => option.values)];
    const state = checkListEmpty(newTimeArray);
    let res;
    if (!state) {
      res = await getFoodOrDrinksId({
        data: newTimeArray,
        memberId: memberData.user_id,
      });
      setDietList?.([res]);
      store.dispatch(
        updateSelectedAnswers({
          ...selectedAnswers,
          diet: res,
          timeArray: [...timeArray],
        })
      );
      navigate("/assessment?assessmentStep=1");
    } else {
      setShowMessage(true);
      setMessage(t("diet-assessment.6"));
      setMessageType("error");
    }
  };

  return (
    <>
      <CustomAlert
        type={messageType}
        open={showMessage}
        closeHandler={() => setShowMessage(false)}
        message={message}
      />
      <div className="summary">
        <Typography variant="h2">{t("new-diet.23")}</Typography>
        <Typography variant="h4">
          {" "}
          {`${t("new-diet.16")} ${
            memberData?.first_name ? memberData?.first_name : ""
          } ${t("new-diet.17")}`}
        </Typography>
        {getSelectedFoodDrinks().map((selectedItem: any) => (
          <Box
            width="100%"
            display="flex"
            padding={{
              xs: "1rem 0",
              sm: "1rem 0",
              md: "1rem",
            }}
            flexDirection="column"
          >
            <Box
              display="flex"
              padding={{
                xs: "1rem 0",
                sm: "1rem 0",
                md: "1rem",
              }}
              alignItems={{
                xs: "flex-start",
                md: "flex-end",
              }}
              gap="2rem"
              flexDirection={{
                xs: "column",
                md: "row",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ textWrap: "nowrap", color: "#005994" }}
              >
                {capitalize(selectedAnswers?.timeAndType?.[selectedItem.name])}{" "}
                <br />
                {selectedItem.name}
              </Typography>
              <Grid
                container
                wrap="wrap"
                spacing={{ xs: 3, md: 2, lg: 0 }}
                rowGap={4}
              >
                {selectedItem.values.map((imageId: string) => (
                  <Grid
                    key={imageId}
                    item
                    className="gridItem"
                    xs={12}
                    sm={6}
                    lg={4}
                    margin="auto"
                    minWidth={{
                      xs: "260px",
                      md: "280px",
                    }}
                  >
                    <MealCard
                      imageId={imageId}
                      title={t(`food-and-drinks.${imageId}`)}
                      imageURL={getImageURL(imageId)}
                      removeHandler={() =>
                        toggleImagesInTimeArray(selectedItem.name, imageId)
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box my="1.5rem" width="100%" height="14px" bgcolor="#00599424" />
          </Box>
        ))}

        <Box
          width="100%"
          display="flex"
          gap="1rem"
          marginTop="1rem"
          flexDirection={{
            xs: "column-reverse",
            md: "row",
          }}
          alignItems={{
            xs: "center",
            md: "flex-end",
            lg: "flex-end",
          }}
          justifyContent={{
            md: "center",
          }}
          mt="1rem"
        >
          <Button
            className="btn"
            variant="contained"
            color="primary"
            style={{ marginTop: "1rem" }}
            onClick={() => {
              navigate("/assessment?assessmentStep=0&type=timeAndType");
              setIsCompleted(true);
            }}
          >
            {t("new-diet.26")}
          </Button>

          {isCompleted ? (
            <Box
              display="flex"
              // alignItems="center"
              gap="0.5rem"
              position="relative"
            >
              <Box
                width="100%"
                height="120px"
                display="flex"
                alignItems="start"
                justifyContent="center"
                position="absolute"
                top="-25px"
                right="0"
                bgcolor="#C8D8E8"
                borderRadius="18px"
                sx={{ cursor: "pointer" }}
                // onClick={() => toggleImagesInTimeArray(imageId)}
              >
                <Typography
                  variant="body2"
                  mt="1rem"
                  padding="0 1rem"
                  color="#000000"
                >
                  {`${t("new-diet.27")} ${
                    memberData?.first_name ? memberData?.first_name : ""
                  } ${t("new-diet.32")}`}
                </Typography>
              </Box>

              <Button
                className="btn"
                variant="contained"
                color="primary"
                style={{
                  marginLeft: "auto",
                  width: "120px",
                  minWidth: "120px",
                }}
                onClick={(ev) => {
                  continueHandler(ev);
                }}
              >
                {t("common.35")}
              </Button>
              <Button
                className="secondary-btn"
                variant="contained"
                color="primary"
                style={{ marginLeft: "auto", width: "120px" }}
                onClick={() => setIsCompleted(false)}
              >
                {t("common.36")}
              </Button>
            </Box>
          ) : (
            <Button
              className="secondary-btn"
              variant="contained"
              color="primary"
              sx={{
                marginLeft: {
                  xs: "0",
                  lg: "auto",
                },
              }}
              onClick={() => setIsCompleted(true)}
            >
              {t("common.34")}
            </Button>
          )}
        </Box>
      </div>
    </>
  );
};

export default DietSummary;
