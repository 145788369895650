import React from "react";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { getCurrentMember } from "../../../features/user/userSlice";
import { useNavigate } from "react-router-dom";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import {
  getSelectedAnswers,
  updateSelectedAnswers,
} from "../../../features/global/globalSlice";
import { store } from "../../../app/store";
import { isEmpty } from "lodash";

const mealTypes = [
  {
    label: " Breakfast",
    value: "breakfast",
  },
  {
    label: " Lunch",
    value: "lunch",
  },
  {
    label: " Dinner",
    value: "dinner",
  },
  {
    label: " Snack",
    value: "snack",
  },
];

const DietTimeType = () => {
  const { t } = useTranslation();
  const memberData = useAppSelector(getCurrentMember);
  const navigate = useNavigate();
  const [typeAnchorEl, setTypeAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const typeOpen = Boolean(typeAnchorEl);
  const [timeAnchorEl, setTimeAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const timeOpen = Boolean(timeAnchorEl);
  const selectedAnswers = useAppSelector(getSelectedAnswers);
  const [selectedTime, setSelectedTime] = React.useState<any>({
    name: "6:00 am",
    label: "6 AM",
    isSelect: false,
    values: [],
  });
  const [selectedMeal, setSelectedMeal] = React.useState<any>({
    label: " Breakfast",
    value: "breakfast",
  });

  const [timeArray, setTimeArray] = React.useState([
    { name: "6:00 am", label: "6 AM", isSelect: false, values: [] },
    { name: "7:00 am", label: "7 AM", isSelect: false, values: [] },
    { name: "8:00 am", label: "8 AM", isSelect: false, values: [] },
    { name: "9:00 am", label: "9 AM", isSelect: false, values: [] },
    { name: "10:00 am", label: "10 AM", isSelect: false, values: [] },
    { name: "11:00 am", label: "11 AM", isSelect: false, values: [] },
    { name: "12:00 pm", label: "12 PM", isSelect: false, values: [] },
    { name: "1:00 pm", label: "1 PM", isSelect: false, values: [] },
    { name: "2:00 pm", label: "2 PM", isSelect: false, values: [] },
    { name: "3:00 pm", label: "3 PM", isSelect: false, values: [] },
    { name: "4:00 pm", label: "4 PM", isSelect: false, values: [] },
    { name: "5:00 pm", label: "5 PM", isSelect: false, values: [] },
    { name: "6:00 pm", label: "6 PM", isSelect: false, values: [] },
    { name: "7:00 pm", label: "7 PM", isSelect: false, values: [] },
    { name: "8:00 pm", label: "8 PM", isSelect: false, values: [] },
    { name: "9:00 pm", label: "9 PM", isSelect: false, values: [] },
    { name: "10:00 pm", label: "10 PM", isSelect: false, values: [] },
    { name: "11:00 pm", label: "11 PM", isSelect: false, values: [] },
    { name: "12:00 am", label: "12 AM", isSelect: false, values: [] },
    { name: "1:00 am", label: "1 AM", isSelect: false, values: [] },
    { name: "2:00 am", label: "2 AM", isSelect: false, values: [] },
    { name: "3:00 am", label: "3 AM", isSelect: false, values: [] },
    { name: "4:00 am", label: "4 AM", isSelect: false, values: [] },
    { name: "5:00 am", label: "5 AM", isSelect: false, values: [] },
  ]);

  const timeHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTimeAnchorEl(event.currentTarget);
  };
  const timeHandleClose = () => {
    setTimeAnchorEl(null);
  };

  const typeHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTypeAnchorEl(event.currentTarget);
  };
  const typeHandleClose = () => {
    setTypeAnchorEl(null);
  };

  const addTimeAndTypeHandler = () => {
    store.dispatch(
      updateSelectedAnswers({
        ...selectedAnswers,
        activeTime: selectedTime,
        timeAndType: {
          ...selectedAnswers?.timeAndType,
          [selectedTime.name]: selectedMeal.value,
        },
        timeArray: [...timeArray],
      })
    );
    navigate("/assessment?assessmentStep=0&type=foodAndDrinks");
  };

  React.useEffect(() => {
    selectedAnswers?.timeArray?.length > 0 &&
      setTimeArray(selectedAnswers.timeArray);
  }, []);

  return (
    <div className="timeAndType">
      <Typography variant="h3">{`${t("new-diet.10")} ${
        memberData?.first_name ? memberData?.first_name : ""
      } ${t("new-diet.11")}`}</Typography>
      <Typography variant="h3">{t("new-diet.12")}</Typography>
      <Box
        mt="2rem"
        display="flex"
        alignItems="center"
        gap="2rem"
        flexDirection={{
          xs: "column",
          sm: "row",
          md: "row",
        }}
      >
        <Box className="foodDrinkBtn foodDrinkBtn--selected">
          {t("new-diet.28")}
        </Box>
        <Box className="language__dropdown">
          <span
            id="basic-button"
            aria-controls={timeOpen ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={timeOpen ? "true" : undefined}
            onClick={timeHandleClick}
            className="category-menu"
          >
            <Box
              display="flex"
              width="250px"
              maxWidth="320px"
              minHeight="50px"
              borderRadius="10px"
              border="2px solid #005994"
              style={{
                cursor: "pointer",
              }}
              flex="1"
            >
              <Typography
                variant="subtitle1"
                color="#787D86"
                fontSize="1.1rem"
                fontWeight="700"
                flex="1"
                textAlign="center"
                margin="auto 0"
              >
                {selectedTime.label}
              </Typography>
              <Box
                bgcolor="#005994"
                borderRadius="0 6px 6px 0"
                display="flex"
                alignItems="center"
              >
                {timeOpen ? (
                  <ArrowDropUp style={{ color: "#fff" }} />
                ) : (
                  <ArrowDropDown style={{ color: "#fff" }} />
                )}
              </Box>
            </Box>
          </span>
          <Menu
            id="basic-menu"
            anchorEl={timeAnchorEl}
            open={timeOpen}
            onClose={timeHandleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              width: "250px",
              maxHeight: "300px",
            }}
            className="dropdown__menu"
          >
            {timeArray.map((time: any) => (
              <MenuItem
                key={time.name}
                onClick={() => {
                  setSelectedTime(time);
                  timeHandleClose();
                }}
              >
                {time.label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
      <Box
        mt="2rem"
        display="flex"
        alignItems="center"
        gap="2rem"
        flexDirection={{
          xs: "column",
          sm: "row",
          md: "row",
        }}
      >
        <Box className="foodDrinkBtn foodDrinkBtn--selected">
          {t("new-diet.29")}
        </Box>
        <Box className="language__dropdown">
          <span
            id="basic-button"
            aria-controls={typeOpen ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={typeOpen ? "true" : undefined}
            onClick={typeHandleClick}
            className="category-menu"
          >
            <Box
              display="flex"
              width="250px"
              maxWidth="320px"
              minHeight="50px"
              borderRadius="10px"
              border="2px solid #005994"
              style={{
                cursor: "pointer",
              }}
              flex="1"
            >
              <Typography
                variant="subtitle1"
                color="#787D86"
                fontSize="1.1rem"
                fontWeight="700"
                flex="1"
                textAlign="center"
                margin="auto 0"
              >
                {selectedMeal.label}
              </Typography>
              <Box
                bgcolor="#005994"
                borderRadius="0 6px 6px 0"
                display="flex"
                alignItems="center"
              >
                {typeOpen ? (
                  <ArrowDropUp style={{ color: "#fff" }} />
                ) : (
                  <ArrowDropDown style={{ color: "#fff" }} />
                )}
              </Box>
            </Box>
          </span>
          <Menu
            id="basic-menu"
            anchorEl={typeAnchorEl}
            open={typeOpen}
            onClose={typeHandleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              width: "250px",
              maxHeight: "300px",
            }}
            className="dropdown__menu"
          >
            {mealTypes.map((meal: any) => (
              <MenuItem
                key={meal.label}
                onClick={() => {
                  setSelectedMeal(meal);
                  typeHandleClose();
                }}
              >
                {meal.label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
      <Button
        className="btn"
        variant="contained"
        color="primary"
        disabled={isEmpty(selectedTime) || isEmpty(selectedMeal)}
        onClick={() => addTimeAndTypeHandler()}
      >
        {t("common.9")}
      </Button>
    </div>
  );
};

export default DietTimeType;
