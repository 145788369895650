import * as React from "react";
import { Box, Typography, Button, Modal } from "@mui/material";
import "./ConfirmationModal.scss";
import { teethIcon } from "../../utils/common/global";
import { useTranslation } from "react-i18next";
import AudioSupport from "../audio-support/AudioSupport";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export interface SubmitParams {
  btnContent?: string;
  submitHandler?: (ev: any) => void;
  disabledRules?: any;
}

export interface CancelParams {
  btnContent?: string;
  cancelHandler?: (ev: any) => void;
}

interface ConfirmationModalProps {
  open: boolean;
  title: any;
  closeHandler: (ev?: any, raison?: string) => void;
  submitParams?: SubmitParams;
  cancelParams?: CancelParams;
  children?: JSX.Element | JSX.Element[];
  showSubmitButton?: boolean;
  showCancelButton?: boolean;
  showPlay?: boolean;
  props?: any;
  audioText?: string;
  showIcon?: boolean;
  HideCloseButton?: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  closeHandler,
  title,
  submitParams,
  cancelParams,
  children,
  showSubmitButton = true,
  showCancelButton = true,
  showPlay = false,
  props,
  audioText = "",
  showIcon = true,
  HideCloseButton = false,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      {...props}
    >
      <Box className="customModal">
        {!HideCloseButton && (
          <Box
            position="absolute"
            top="10px"
            right="10px"
            onClick={() => closeHandler()}
          >
            <HighlightOffIcon />
          </Box>
        )}

        {showIcon && (
          <Box mt="1rem">
            <img src={teethIcon} alt="" className="customModal__img" />
          </Box>
        )}

        <Box className="customModal__content">
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            className="title"
          >
            {title}
          </Typography>
          <Box width="100%">
            {showPlay && (
              <AudioSupport
                audioText={audioText}
                showText={false}
                width="100%"
                padding="4px 0"
              />
            )}
            {children}
          </Box>
          <Box className="btnContainer" mt={4}>
            {showCancelButton && (
              <Button
                className="btn cancelBtn"
                variant="outlined"
                onClick={(ev) => {
                  cancelParams?.cancelHandler
                    ? cancelParams.cancelHandler(ev)
                    : closeHandler();
                }}
              >
                {cancelParams?.btnContent
                  ? cancelParams.btnContent
                  : t("common.2")}
              </Button>
            )}

            {showSubmitButton && (
              <Button
                variant="contained"
                className="btn confirmBtn"
                onClick={(ev) => {
                  submitParams?.submitHandler && submitParams.submitHandler(ev);
                }}
                disabled={submitParams?.disabledRules ?? false}
              >
                {submitParams?.btnContent ? submitParams.btnContent : "Yes"}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
