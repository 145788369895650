import * as React from "react";
import {
  Box,
  Typography,
  Button,
  AlertColor,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import "./ActionPlanning.scss";
import CustomStepper from "../../components/customStepper/CustomStepper";
import { useNavigate } from "react-router-dom";
import { firstFollowUpFlyer } from "../../utils/common/global";
import CustomHint from "../../components/custom-hint/CustomHint";
import ActionItem from "./components/action-item/ActionItem";
import { checkEmpty } from "../../utils/common/checkEmpty";
import { Close } from "@mui/icons-material";
import CustomAlert from "../../components/custom-alert/CustomAlert";
import {
  createNewPlan,
  getCurrentActivePlan,
  notificationSchedule,
  removeInactivePlanFromRemaining,
  sendFirstNotif,
  updateMemberTrackingStatus,
  updatePlanToIsPrevious,
} from "../../features/user/asyncActions";
import { useAppSelector } from "../../app/hooks";
import { getUserData } from "../../features/auth/authSlice";
import {
  getActiveRiskAndGoal,
  getCurrentMember,
} from "../../features/user/userSlice";
import { useTranslation } from "react-i18next";
import {
  getGlobalLanguage,
  risksAssessmentAnswers,
  showMember,
  updateSelectedAnswers,
  updateShowMember,
} from "../../features/global/globalSlice";
import moment from "moment";
import { store } from "../../app/store";
import LanguageSupport from "../../components/language-support/LanguageSupport";

const ActionPlanning = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openFlyerModal, setOpenFlyerModal] = React.useState(false);
  const [howlong, setHowlong] = React.useState<string>("");
  const [responsible, setResponsible] = React.useState<string>("");
  const [startDateTime, setStartDateTime] = React.useState<string>("");
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [messageType, setMessageType] = React.useState<AlertColor>("error");
  const userData = useAppSelector(getUserData);
  const memberData = useAppSelector(getCurrentMember);
  const currentGoal = useAppSelector(getActiveRiskAndGoal);
  const language = useAppSelector(getGlobalLanguage);
  const showMemberStatus = useAppSelector(showMember);

  const updateCurrentActivePlanHandler = async (ev: any) => {
    const { data } = await getCurrentActivePlan(memberData?.user_id);
    if (data?.plan_id) {
      await updatePlanToIsPrevious({
        planId: data?.plan_id,
        result: "",
      });
      await removeInactivePlanFromRemaining(data?.plan_id);
    }
  };

  const submitHandler = async (ev: any) => {
    ev.preventDefault();
    const data = {
      responsible,
      startDateTime,
      howlong,
    };
    const state = checkEmpty(data);
    if (state) {
      await updateCurrentActivePlanHandler(ev);
      const { data } = await createNewPlan({
        memberId: memberData?.user_id,
        goalId: currentGoal?.goalId,
        description: "plan",
        start_date: startDateTime,
        who: responsible,
        easier_factors: null,
        things_to_overcome: responsible,
        how_long: howlong,
      });
      const how_long: any = data?.memberPlan?.how_long;
      const startDate: any = data?.memberPlan?.start_date;
      const timestamp = data?.memberPlan?.timestamp;
      const { howLongId } = await notificationSchedule({
        member_id: memberData?.user_id,
        how_long: how_long,
        plan_id: data?.memberPlan?.plan_id,
      });

      if (
        moment(startDate).format("MM-DD-YYYY") ===
        moment(timestamp).format("MM-DD-YYYY")
      ) {
        await sendFirstNotif({
          group_id: userData?.group_id,
          howlong_id: howLongId,
          goal_id: currentGoal?.goalId,
          member_id: memberData?.user_id,
          member_name: memberData?.first_name,
          responsible,
          startDate,
          how_long,
        });
      }
      await updateMemberTrackingStatus(memberData?.user_id, 2);
      store.dispatch(updateSelectedAnswers(risksAssessmentAnswers));
      navigate(`/action-planning-tutorial`);
    } else {
      setShowMessage(true);
      setMessage(t("common.16"));
      setMessageType("error");
    }
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  React.useEffect(() => {
    !showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CustomAlert
        type={messageType}
        open={showMessage}
        closeHandler={() => setShowMessage(false)}
        message={message}
      />
      <Dialog
        className="dialog"
        open={openFlyerModal}
        onClose={(_, raison = "backdropClick") => {
          if (raison !== "backdropClick") setOpenFlyerModal(false);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="dialogTitle">
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Close
              sx={{ fontSize: "1.5rem", cursor: "pointer" }}
              onClick={() => setOpenFlyerModal(false)}
            />
          </Box>
        </DialogTitle>
        <DialogContent className="dialogContent">
          <img
            src={firstFollowUpFlyer(language, currentGoal?.goalId)}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </DialogContent>
      </Dialog>
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="actionPlanningPage">
        <Box className="actionPlanningPage__contentContainer">
          <Box className="subMain">
            <Box className="actionContainer">
              <Box className="actionHeader">
                <Box className="goal">
                  <Typography className="goalTitle">
                    {t(`all_goals.${currentGoal?.goalId}`)}
                  </Typography>
                </Box>
                <Typography className="username">
                  {memberData?.first_name} {memberData?.last_name}
                </Typography>
              </Box>
              <Grid container spacing={4} className="actionContentContainer">
                <Grid
                  item
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                >
                  <CustomHint
                    text={t("action-planning.3")}
                    bottom="-40px"
                    left="-45px"
                    showPlay
                    audioText={t("action-planning.3")}
                  />
                  <Box
                    display="block"
                    sx={{ cursor: "pointer" }}
                    onClick={() => setOpenFlyerModal(true)}
                  >
                    <img
                      src={firstFollowUpFlyer(language, currentGoal?.goalId)}
                      alt=""
                      style={{
                        maxWidth: "500px",
                        width: "100%",
                        height: "auto",
                        marginTop: "3rem",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7}>
                  <Box className="actionContent">
                    <ActionItem
                      key={1}
                      title={t("action-planning.4")}
                      text={t("action-planning.5")}
                      type="text"
                      selectedValue={responsible}
                      onChange={(ev) => setResponsible(ev.target.value)}
                      messageType={messageType}
                    />
                    <ActionItem
                      key={2}
                      title={t("action-planning.6")}
                      text={t("action-planning.7")}
                      type="date"
                      selectedValue={startDateTime}
                      onChange={(ev) => setStartDateTime(ev.target.value)}
                      messageType={messageType}
                    />
                    <ActionItem
                      key={3}
                      title={t("action-planning.8")}
                      text={t("action-planning.9")}
                      type="select"
                      selectedValue={howlong}
                      onChange={(ev) => setHowlong(ev.target.value)}
                      messageType={messageType}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box className="btnContainer">
                <Button
                  className="btn backBtn"
                  onClick={() => navigate(-1)}
                  variant="contained"
                >
                  {t("common.13")}
                </Button>
                <Button
                  className="btn continueBtn"
                  onClick={(ev) => {
                    submitHandler(ev);
                  }}
                  variant="outlined"
                >
                  {t("common.14")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "50%" }}>
        <CustomStepper activeStep={3} />
      </Box>
    </>
  );
};

export default ActionPlanning;
